// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
const createGuard = z.object({
    id: z.string().min(1).max(21),
    tenantId: z.string().max(21).optional(),
    applicationId: z.string().min(1).max(21),
    privateKey: z.string().min(1),
    certificate: z.string().min(1),
    createdAt: z.number().optional(),
    expiresAt: z.number(),
    active: z.boolean(),
});
const guard = z.object({
    id: z.string().min(1).max(21),
    tenantId: z.string().max(21),
    applicationId: z.string().min(1).max(21),
    privateKey: z.string().min(1),
    certificate: z.string().min(1),
    createdAt: z.number(),
    expiresAt: z.number(),
    active: z.boolean(),
});
export const SamlApplicationSecrets = Object.freeze({
    table: 'saml_application_secrets',
    tableSingular: 'saml_application_secret',
    fields: {
        id: 'id',
        tenantId: 'tenant_id',
        applicationId: 'application_id',
        privateKey: 'private_key',
        certificate: 'certificate',
        createdAt: 'created_at',
        expiresAt: 'expires_at',
        active: 'active',
    },
    fieldKeys: [
        'id',
        'tenantId',
        'applicationId',
        'privateKey',
        'certificate',
        'createdAt',
        'expiresAt',
        'active',
    ],
    createGuard,
    guard,
    updateGuard: guard.partial(),
});
