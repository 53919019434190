import { z } from 'zod';
export const samlAttributeMappingGuard = z.record(z.string());
export var BindingType;
(function (BindingType) {
    BindingType["POST"] = "urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST";
    BindingType["REDIRECT"] = "urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect";
})(BindingType || (BindingType = {}));
export const samlAcsUrlGuard = z.object({
    binding: z.nativeEnum(BindingType),
    url: z.string(),
});
